import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Step } from 'src/types';

export type UserResponsesSummary = {
  id: string;
  status: string;
  channel: string;
  dateSent: string;
  dateDelivered: string;
  dateViewed: string;
  dateOpened: string;
  dateCompleted: string;
};

/*
  A service to exclusively set a selected user in a limited scope inside zelo details
*/
@Injectable({ providedIn: 'root' })
export class SelectedUserService {
  constructor(private http: HttpClient) {}

  private emitChangeSource = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();

  emitChange(change: { user: any; response?: any }) {
    this.emitChangeSource.next(change);
  }

  getUserResponses(userId: string): Observable<UserResponsesSummary[]> {
    return this.http.get<UserResponsesSummary[]>(
      `${environment.api}/v1/users/${userId}/responses/summary`
    );
  }

  extendedResponseInfo(
    responseId: string
  ): Observable<{ steps: Step[]; remindedCount: number }> {
    return this.http.get<any>(
      `${environment.api}/v1/responses/${responseId}/extendedinfo`
    );
  }
}
