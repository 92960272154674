import { Injectable, OnDestroy } from '@angular/core';
import {
  Zelo,
  ZeloChannelMessage,
  Channels,
  RecipientChannel,
  ZeloMode,
  AttrZelo
} from 'src/types';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromZelos from '../reducers';
import * as fromAuth from '../../../auth/reducers';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { openRow } from './../utils/bee-template-data';
import {
  setZeloMode,
  upsertZeloChannelMessages
} from '../actions/zelo.next.actions';

type statusType = 'idle' | 'success' | 'warning';

export interface ValidationStatus {
  audience: statusType;
  channels: statusType;
  channelMessages: { status: statusType; details: string[] };
  schedule: statusType;
  content: statusType;
  readyToSend: boolean;
}

@Injectable({ providedIn: 'root' })
export class ZeloUtilityService implements OnDestroy {
  selectedZelo$ = this.store.pipe(select(fromZelos.getSelectedZelo));

  zeloStatus$ = combineLatest([
    this.selectedZelo$,
    this.store.pipe(select(fromAuth.getOrganizationChannels))
  ]).pipe(
    map(([zelo, channels]) => {
      return this.decideZeloStatus({ zelo, channels });
    })
  );

  computeSubscription: Subscription;

  constructor(
    private store: Store<ValidationStatus>,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {
    this.computeSubscription = this.zeloStatus$.subscribe();
  }

  ngOnDestroy(): void {
    this.computeSubscription.unsubscribe();
  }

  private decideZeloStatus({
    zelo,
    channels
  }: {
    zelo: Zelo;
    channels: Channels;
  }) {
    const status: ValidationStatus = {
      audience:
        (zelo?.audience2 as AttrZelo)?.filters?.length > 0
          ? 'success'
          : 'warning',
      channels: 'success',
      channelMessages: this.getChannelMessageStatus(
        zelo,
        channels || {},
        zelo?.channelMessages || []
      ),
      schedule: 'success',
      content:
        zelo.mode === 'zelo_content'
          ? zelo.isValid
            ? 'success'
            : 'warning'
          : null,
      readyToSend: false
    };

    return {
      ...status,
      readyToSend: this.readyToSend(status, zelo)
    };
  }

  readyToSend(status: any, zelo: Zelo) {
    return (
      status.audience === 'success' &&
      status.channels === 'success' &&
      status.channelMessages.status === 'success' &&
      status.schedule === 'success' &&
      (zelo.mode === 'zelo_content' ? status.content === 'success' : true)
    );
  }

  getChannelMessageStatus(
    zelo: Zelo,
    channels: Channels,
    channelMessages: ZeloChannelMessage[]
  ) {
    const validations: string[] = [];
    const i18n = 'composer.toolbar.sendConfirmationModal.channels.';

    const i18nMap = {
      email: i18n + 'emailMissing',
      emailButton: i18n + 'emailButtonMissing',
      slack: i18n + 'slackMissing',
      sms: i18n + 'textMissing',
      teams: i18n + 'teamsMissing',
      whatsapp: i18n + 'whatsappMissing',
      workplace: i18n + 'workplaceMissing'
    };

    const emptyState: { status: statusType; details: string[] } = {
      status: 'warning',
      details: [`${i18n}emptystate`]
    };

    if (
      !zelo ||
      !zelo.id ||
      !channels ||
      !Object.keys(channels).length ||
      !channelMessages.length
    ) {
      return emptyState; // compute$ still being computed
    }

    let channelsToValidate: string[] = ['email'];

    if (zelo.channel) {
      // zelo specifies a primary channel
      const c = channelMessages.find((m) => m.channel === zelo.channel);

      if (c && channels[c.channel as RecipientChannel]) {
        channelsToValidate = [c.channel]; // primary

        if (c.channel !== 'email') {
          channelsToValidate.push('email'); // backup
        }
      }
    }

    if (!channelsToValidate.length) {
      return emptyState;
    }

    for (const channelName of channelsToValidate) {
      const channel = channels[channelName as RecipientChannel];

      const channelMessage = channelMessages.find(
        (msg) => msg.channelId === channel.id
      );

      const props = channelMessage.messageProperties;
      const okSubject = !!props.subject || channelName !== 'email';

      const okMessage =
        !!props.message || (channelName === 'email' && props.messageJSON);

      if (!okSubject || !okMessage) {
        validations.push(i18nMap[channelName as RecipientChannel]);
      }

      if (channelName === 'email' && zelo.mode === 'zelo_content') {
        const emailJSON =
          typeof props.messageJSON === 'string' && props.messageJSON.length
            ? JSON.parse(props.messageJSON)
            : props.messageJSON;

        const hasOpenLink = emailJSON.page?.rows?.some(
          (row: any) => row.type === 'open_link'
        );

        if (!hasOpenLink) {
          validations.push(i18nMap['emailButton' as RecipientChannel]);
        }
      }
    }

    const isValid = validations.length === 0;
    const status: statusType = isValid ? 'success' : 'warning';
    const details: string[] = isValid ? [`${i18n}readyState`] : validations;
    return { status, details };
  }

  huzza(i18n: string, substitutions: object = {}): void {
    const toastClass = { toastClass: 'ngx-toastr zelo-toast-success' };
    const success = this.translate.instant('toasts.success');
    const message = this.translate.instant(`toasts.${i18n}`, substitutions);
    this.toastr.show(message, success, toastClass);
  }

  brief(i18n: string, substitutions: object = {}): void {
    const options = {
      toastClass: 'ngx-toastr zelo-toast-warning',
      timeOut: 15000,
      extendedTimeOut: 60000,
      closeButton: true,
      tapToDismiss: true,
      progressBar: true,
      enableHtml: true
    };

    const message = this.translate.instant(`toasts.${i18n}`, substitutions);
    this.toastr.show(message, null, options);
  }

  oops(i18n: string, substitutions: object = {}, title?: string): void {
    const options = {
      toastClass: 'ngx-toastr zelo-toast-alert',
      timeOut: 15000,
      extendedTimeOut: 60000,
      closeButton: true,
      tapToDismiss: true,
      progressBar: true,
      enableHtml: true
    };

    title = title ?? this.translate.instant('toasts.failure');
    const href = 'javascript:window.location.href=window.location.href';

    const subs = {
      ...substitutions,
      buttonOpen: `<div class="flex center margin-tm"><a class="button" href="${href}">`,
      buttonClose: '</a></div>'
    };

    const message = this.translate.instant(`toasts.${i18n}`, subs);

    this.toastr
      .show(message, title, options)
      .onHidden.subscribe(() => window.location.reload());
  }

  handleZeloMode() {
    let zelo: Zelo;
    this.selectedZelo$
      .subscribe((selectedZelo) => (zelo = selectedZelo))
      .unsubscribe();

    let emailMessageJSON = zelo.channelMessages.find((cm) => {
      return cm.channel === 'email';
    }).messageProperties.messageJSON;

    typeof emailMessageJSON === 'string'
      ? (emailMessageJSON = JSON.parse(emailMessageJSON))
      : (emailMessageJSON = emailMessageJSON);

    if (zelo.mode === 'zelo_content') {
      const hasOpenLink = emailMessageJSON.page.rows.some(
        (row: any) => row.type === 'open_link'
      );

      if (!hasOpenLink) {
        emailMessageJSON.page.rows.push(openRow as any);
      }
    } else {
      emailMessageJSON.page.rows = emailMessageJSON.page.rows.filter(
        (row: any) => row.type !== 'open_link'
      );
    }

    const otherChannelMessages = zelo.channelMessages.filter(
      (cm) => cm.channel !== 'email'
    );

    const channelMessagesToSave = [
      ...otherChannelMessages,
      {
        ...zelo.channelMessages.find((cm) => cm.channel === 'email'),
        messageProperties: {
          ...zelo.channelMessages.find((cm) => cm.channel === 'email')
            .messageProperties,
          messageJSON: JSON.stringify(emailMessageJSON)
        }
      }
    ];

    this.store.dispatch(
      upsertZeloChannelMessages({ channelMessages: channelMessagesToSave })
    );
  }

  handleSelectedMode(event: any) {
    let zelo: Zelo;
    this.selectedZelo$
      .subscribe((selectedZelo) => (zelo = selectedZelo))
      .unsubscribe();

    zelo.mode = String(event) as ZeloMode;
    this.store.dispatch(setZeloMode({ id: zelo.id, mode: zelo.mode }));
    this.handleZeloMode();

    if (zelo.mode === 'zelo_content') {
      const toastClass = { toastClass: 'ngx-toastr zelo-toast-success' };
      const success = 'Landing Page Added';
      const message = 'An open button has been added to the email template.';
      this.toastr.show(message, success, toastClass);
    }

    if (zelo.mode !== 'zelo_content') {
      const toastClass = { toastClass: 'ngx-toastr zelo-toast-success' };
      const success = 'Landing Page Removed';
      const message =
        'The open button has been deleted from the email template.';
      this.toastr.show(message, success, toastClass);
    }
  }
}
