export const openRow = {
  columns: [
    {
      'grid-columns': 12,
      modules: [
        {
          type: 'mailup-bee-newsletter-modules-button',
          descriptor: {
            button: {
              label:
                '<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 24px;" data-mce-style="font-size: 12px; line-height: 24px;"><p style="font-size: 16px; line-height: 32px; word-break: break-word;" data-mce-style="font-size: 16px; line-height: 32px;">Open</p></div>',
              href: '{{{user_open_link}}}',
              style: {
                'font-family': 'inherit',
                'background-color': '#3AAEE0',
                'border-radius': '4px',
                'border-top': '0px solid transparent',
                'border-right': '0px solid transparent',
                'border-bottom': '0px solid transparent',
                'border-left': '0px solid transparent',
                color: '#ffffff',
                'line-height': '200%',
                'padding-top': '5px',
                'padding-right': '20px',
                'padding-bottom': '5px',
                'padding-left': '20px',
                width: 'auto',
                'max-width': '100%'
              }
            },
            style: {
              'text-align': 'center',
              'padding-top': '10px',
              'padding-right': '10px',
              'padding-bottom': '10px',
              'padding-left': '10px'
            },
            computedStyle: {
              width: 79,
              height: 42,
              hideContentOnMobile: false
            }
          },
          locked: false
        }
      ],
      style: {
        'background-color': 'transparent',
        'border-bottom': '0px solid transparent',
        'border-left': '0px solid transparent',
        'border-right': '0px solid transparent',
        'border-top': '0px solid transparent',
        'padding-bottom': '30px',
        'padding-left': '0px',
        'padding-right': '0px',
        'padding-top': '30px'
      }
    }
  ],
  container: {
    style: {
      'background-color': 'transparent',
      'background-image': 'none',
      'background-position': 'top left',
      'background-repeat': 'no-repeat'
    }
  },
  content: {
    computedStyle: { rowColStackOnMobile: true },
    style: {
      'background-color': 'transparent',
      'background-image': 'none',
      'background-position': 'top left',
      'background-repeat': 'no-repeat',
      color: '#333',
      width: '500px'
    }
  },
  locked: false,
  type: 'open_link'
};

export const defaultTemplate: any = {
  overrideEditorState: true,
  message: {
    page: {
      body: {
        container: {
          style: {
            'background-color': '#f8f8f8'
          }
        },
        content: {
          computedStyle: {
            align: 'center',
            linkColor: '#4e3f9e',
            messageBackgroundColor: '#ffffff',
            messageWidth: '580px'
          },
          style: {
            color: '#000000',
            'font-family': 'Helvetica Neue, Helvetica, Arial, sans-serif'
          }
        },
        type: 'mailup-bee-page-properties',
        webFonts: []
      },
      description: '',
      rows: [
        {
          columns: [
            {
              'grid-columns': 12,
              modules: [
                {
                  descriptor: {
                    computedStyle: {
                      align: 'center',
                      hideContentOnMobile: false
                    },
                    divider: {
                      style: {
                        'border-top': '1px solid #F8F8F8',
                        width: '100%'
                      }
                    },
                    style: {
                      'padding-bottom': '10px',
                      'padding-left': '10px',
                      'padding-right': '10px',
                      'padding-top': '10px'
                    }
                  },
                  type: 'mailup-bee-newsletter-modules-divider'
                }
              ],
              style: {
                'background-color': 'transparent',
                'border-bottom': '0px solid transparent',
                'border-left': '0px solid transparent',
                'border-right': '0px solid transparent',
                'border-top': '0px solid transparent',
                'padding-bottom': '5px',
                'padding-left': '5px',
                'padding-right': '5px',
                'padding-top': '5px'
              }
            }
          ],
          container: {
            style: {
              'background-color': '#f8f8f8',
              'background-image': 'none',
              'background-position': 'top left',
              'background-repeat': 'no-repeat'
            }
          },
          content: {
            computedStyle: {
              rowColStackOnMobile: true,
              rowReverseColStackOnMobile: false
            },
            style: {
              'background-color': '#f8f8f8',
              'background-image': 'none',
              'background-position': 'top left',
              'background-repeat': 'no-repeat',
              color: '#000000',
              width: '500px'
            }
          },
          type: 'one-column-empty'
        }
      ],
      template: {
        name: 'template-base',
        type: 'basic',
        version: '2.0.0'
      },
      title: ''
    }
  }
};
