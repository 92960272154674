import { createAction, props } from '@ngrx/store';
import { DistributorJob, Zelo, ZeloSummary } from 'src/types';

export type DistributorReportParams = {
  beginDaysAgo?: number;
  endDaysAgo?: number;
  sizeLimit?: number;
  focus?: Partial<Zelo> | Partial<ZeloSummary>[];
};

export type DistributorReport = {
  params?: DistributorReportParams;
  stuckJobs?: [
    {
      id: string;
    }
  ];
  byJobType?: [
    {
      'Job Type': string;
      'Job Count': number;
    }
  ];
  byOrganization?: [
    {
      'Org ID': string;
      Organization: string;
      'Job Count': number;
    }
  ];
  sendingWindows?: [
    {
      'Sending Window': string;
      'Start At UTC': Date;
      'Start At Date': string;
      'Start At Hour': number;
      'Start At Minute': number;
      'Start At Timezone': string;
      'Finish By UTC': Date;
      'Finish By Date': string;
      'Finish By Hour': number;
      'Finish By Minute': number;
      'Finish By Timezone': string;
      Tasks: number;
      Sent: number;
      Scheduled: number;
      Organization: string;
      Title: string;
      'Zelo Mode': string;
      'Zelo ID': string;
      'Job ID': string;
    }
  ];
  percentiles?: [
    {
      'Job ID': string;
      Percentile: number;
      Timestamp: Date;
    }
  ];
  velocities?: [
    {
      'Job ID': string;
      'Avg Velocity': number;
    }
  ];
  benchmarks?: [
    {
      'Job ID': string;
      'Sample Size': number;
      'Min Prepare (ms)': number;
      'Max Prepare (ms)': number;
      'Avg Prepare (ms)': number;
      'Median Prepare (ms)': number;
      'Std Prepare (ms)': number;
      'Min Send (ms)': number;
      'Max Send (ms)': number;
      'Avg Send (ms)': number;
      'Median Send (ms)': number;
      'Std Send (ms)': number;
      Channel: string;
    }
  ];
  snapshot?: [
    {
      'Org Name': string;
      'Zelo Title': string;
      'Job Size': number;
      Sent: number;
      'Zelo Mode': string;
      'Zelo ID': string;
      'Org ID': string;
      'Job ID': string;
      'Job Type': string;
      Forecast: [
        {
          'Send At': Date;
          'First Name': string;
          'Last Name': string;
        }
      ];
    }
  ];
  isAutomatedTest?: boolean;
  delivered?: number;
  jobs?: Record<string, Record<string, Partial<DistributorJob>>>;
};

enum Names {
  FetchDistributorReport = '[Admin] Fetch Distributor Report',
  FetchDistributorReportSuccess = '[Admin] Fetch Distributor Report Success',
  SetDistributorReportFocus = 'Set Distributor Report Focus'
}

export const fetchDistributorReport = createAction(
  Names.FetchDistributorReport,
  props<DistributorReportParams>()
);

export const fetchDistributorReportSuccess = createAction(
  Names.FetchDistributorReportSuccess,
  props<any>()
);

export const setDistributorReportFocus = createAction(
  Names.SetDistributorReportFocus,
  props<{ focus: Partial<Zelo> | Partial<ZeloSummary>[] }>()
);
