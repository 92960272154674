import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import { Zelo, ZeloSummary } from 'src/types';

import {
  DistributorReport,
  fetchDistributorReportSuccess,
  setDistributorReportFocus
} from '../actions/admin.actions';

/**
 * AdminView is a part of the application state with the
 * following characteristics:
 * 1) The data flows one way from the database
 * 2) The client does not change data
 * 3) The data is eventually-consistent, as from a read-replica
 */

export interface AdminView {
  distributorReport: DistributorReport;
  distributorReportFocus: Partial<Zelo> | Partial<ZeloSummary>[];
}

export const initialView: AdminView = {
  distributorReport: null,
  distributorReportFocus: null
};

export const AdminViewReducer = createReducer(
  initialView,
  on(fetchDistributorReportSuccess, (view, action) => {
    const delivered = action.snapshot?.delivered;
    const jobs = { ...(view.distributorReport || {}).jobs } || {};

    for (const r of action.snapshot?.jobs || []) {
      jobs[r.zeloId] = jobs[r.zeloId] || {};
      jobs[r.zeloId][r.jobId] = r;
    }

    const distributorReport = { delivered, jobs };
    return { ...view, distributorReport };
  }),
  on(setDistributorReportFocus, (state, { focus }) => {
    return {
      ...state,
      distributorReportFocus: focus
    };
  })
);

const adminViewFeature = createFeatureSelector<AdminView>('adminView');

export const getDistributorReportView = createSelector(
  adminViewFeature,
  (readOnlyData) => (readOnlyData as AdminView).distributorReport || {}
);

export const getDistributorReportFocus = createSelector(
  adminViewFeature,
  (readOnlyData) => (readOnlyData as AdminView).distributorReportFocus
);
